import React, { FunctionComponent, useContext, useEffect } from 'react';
import {
  ChatScrollWrapper,
  ChatInput,
  ChatComments,
  ChatLoginPrompt,
  ChatEmpty
} from 'features/chat/components';
import { Loader } from 'components/Loader';
import { useRecentMessages } from 'features/chat/chatHooks';
import { useCurrentUser } from 'features/current-user/hooks';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { usePageVisibility } from 'hooks/usePageVisibility';
import { fetchChatHistory } from 'features/chat/chatSlice';
import { useDispatch } from 'react-redux';
import { usePageLoaded } from 'hooks/usePageLoaded';
import { SEOHeaderTags } from 'components/SEO/SEOHeaderTags';
import { Error404 } from 'components/ErrorPages/Error404';
import { useAppSelector } from 'hooks/redux';
import { WebSocketContext } from 'features/hub/hub';
import { liveEventSelector } from 'features/events/eventsSelectors';
import { EventModel } from 'features/events/EventModel';
import { loadEvent } from 'thunks/navigationThunks';
import { useEventFetcher } from 'features/events/eventHooks';

export const ChannelChat: FunctionComponent = () => {
  const isLoaded = useAppSelector((state) => state.chat.isLoaded);
  const channelId = useAppSelector(
    (state) => state.channel.channelDetails?.ownerId
  );
  const currentUser = useCurrentUser();
  const { isNativeAppWebview, isEmbed } = useContext(UserAgentContext);
  const messages = useRecentMessages(isEmbed);
  const isPageVisible = usePageVisibility();
  const dispatch = useDispatch();
  const isHubConnected = useAppSelector((state) => state.hub.isHubConnected);
  const isHubCrowdConnected = useAppSelector(
    (state) => state.hub.isHubCrowdConnected
  );
  const { joinCrowd } = useContext(WebSocketContext);
  const isCrowdVisible = useAppSelector((state) => state.crowd.isCrowdVisible);
  const liveEvent: EventModel | null = useAppSelector(liveEventSelector);
  const { event } = useEventFetcher(liveEvent?.id);

  usePageLoaded();

  useEffect(() => {
    dispatch(loadEvent(event?.id || null));
  }, [event, dispatch]);

  useEffect(() => {
    if (channelId && isPageVisible) {
      dispatch(fetchChatHistory(channelId, isEmbed));
    }
  }, [dispatch, channelId, isPageVisible, isEmbed]);

  useEffect(() => {
    if (isHubConnected && !isHubCrowdConnected) {
      joinCrowd();
    }
  }, [joinCrowd, isHubConnected, isHubCrowdConnected]);

  const renderChatInput = () => {
    if (isEmbed || isCrowdVisible) {
      return null;
    } else if (currentUser && channelId) {
      return <ChatInput channelId={channelId} />;
    } else {
      return <ChatLoginPrompt />;
    }
  };

  if (!isLoaded || !channelId) {
    return <Loader fillComponent={true} hideOnNative={isNativeAppWebview} />;
  }

  if (isNativeAppWebview || isEmbed) {
    return (
      <>
        <SEOHeaderTags title={'Chat'} description={''} image={''} url={''} />
        <div className="tw-flex tw-flex-col tw-h-full tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-text-label">
          <ChatScrollWrapper>
            {messages.length > 0 ? (
              <ChatComments messages={messages} />
            ) : (
              <ChatEmpty />
            )}
          </ChatScrollWrapper>
          {renderChatInput()}
        </div>
      </>
    );
  }

  return <Error404 />;
};
