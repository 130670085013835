import React, { FunctionComponent, useContext } from 'react';
import { EventTimer } from 'features/events/EventTimer';
import styles from '../styles.module.scss';
import { useCurrentEvent, useIsEventLive } from 'features/events/eventHooks';
import { useAppSelector } from 'hooks/redux';
import { currentEventBroadcastSelector } from 'features/broadcast/selectors';
import useNetwork from 'hooks/useNetwork';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { useEventChatWithPlayer } from 'features/chat/chatHooks';

export const EventMeta: FunctionComponent = () => {
  const loading = useAppSelector((state) => state.audio.loading);
  const playing = useAppSelector((state) => state.audio.playing);
  const miniPlayerVisible = useAppSelector(
    (state) => state.channel.miniPlayerVisible
  );
  const connecting = useAppSelector((state) => state.audio.connecting);
  const { isOnline } = useNetwork();
  const { isCreatorAppWebview, isEmbed } = useContext(UserAgentContext);
  const eventChatWithPlayer = useEventChatWithPlayer();

  const event = useCurrentEvent();
  const currentBroadcast = useAppSelector(currentEventBroadcastSelector);

  const isLive = useIsEventLive(event);

  if (!event || !currentBroadcast || isCreatorAppWebview) {
    return null;
  }

  const renderStatusText = () => {
    if (isLive) {
      if (!isOnline) {
        return 'Offline';
      } else if (connecting || loading) {
        return <span className="loading-text">Connecting</span>;
      } else if (playing) {
        return <EventTimer event={event} broadcast={currentBroadcast} />;
      }
      return miniPlayerVisible || eventChatWithPlayer || (isEmbed && 'On Air');
    }
    return 'Off-Air';
  };

  return (
    <div className={styles['event-view__player-state']}>
      {renderStatusText()}
    </div>
  );
};
