import { EventModel } from 'features/events/EventModel';
import {
  eventSelector,
  privateEventSelector
} from 'features/events/eventsSelectors';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { fetchEvent } from 'features/events/eventsSlice';
import { useDispatch } from 'react-redux';
import { useEffect, useContext, useRef } from 'react';
import { useSubdomain } from 'hooks/useSubdomain';
import { currentBroadcastFinishedSelector } from 'features/broadcast/selectors';
import { useNavigate } from 'react-router-dom';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { useNativeEvents } from 'hooks/useNativeEvents';
import { createAlert } from 'features/alert/alertSlice';

export const useEvent = (id?: string): EventModel | null => {
  return useSelector((state: RootState) => eventSelector(state, id));
};

export const usePrivateEvent = (id?: string): EventModel | null => {
  return useSelector((state: RootState) => privateEventSelector(state, id));
};

export const useCurrentEvent = (): EventModel | null => {
  const currentEventId = useSelector(
    (state: RootState) => state.events.currentEventId
  );
  return useSelector((state: RootState) =>
    eventSelector(state, currentEventId)
  );
};

export const useEventFetcher = (eventId?: string) => {
  const dispatch = useDispatch();
  const event = useEvent(eventId);
  const privateEvent = usePrivateEvent(eventId);
  const subdomain = useSubdomain();
  const navigate = useNavigate();
  const { isEmbed, isCreatorAppWebview } = useContext(UserAgentContext);
  const isAnimatingLayout = useSelector(
    (state: RootState) => state.ui.isAnimatingLayout
  );
  const { postMessage } = useNativeEvents();

  useEffect(() => {
    if (eventId && !event && !privateEvent && !isAnimatingLayout) {
      dispatch(
        fetchEvent(
          subdomain,
          eventId,
          navigate,
          isEmbed,
          isCreatorAppWebview,
          postMessage
        )
      );
    }
  }, [
    eventId,
    dispatch,
    event,
    privateEvent,
    subdomain,
    isEmbed,
    isCreatorAppWebview,
    navigate,
    isAnimatingLayout,
    postMessage
  ]);

  return { event, privateEvent };
};

export const useIsEventLive = (event: EventModel | null): boolean => {
  const playing = useSelector((state: RootState) => state.audio.playing);
  const broadcastFinished = useSelector(currentBroadcastFinishedSelector);
  const lastAudioEnded = useSelector(
    (state: RootState) => state.broadcasts.lastAudioEndedTimestamp
  );

  if (!event) {
    return false;
  }

  return playing || event.isLive() || (!!lastAudioEnded && !broadcastFinished);
};

export const useCreatorAppDAEventUpdated = (event: EventModel | null) => {
  const dispatch = useDispatch();
  const { isDACreatorApp, isCreatorAppWebview } = useContext(UserAgentContext);
  const { postMessage } = useNativeEvents();
  const currentUpdatedAtRef = useRef<string | undefined>(undefined);
  if (!event || !isCreatorAppWebview) return;
  if (
    currentUpdatedAtRef.current &&
    currentUpdatedAtRef.current !== event.lastUpdatedAt &&
    event.lastHubEventType === 'updated' &&
    isDACreatorApp
  ) {
    dispatch(createAlert({ message: 'Event updated', type: 'success' }));
  }
  currentUpdatedAtRef.current = event.lastUpdatedAt;
  postMessage({
    eventTitle: event.title,
    categoryId: event.categoryId
  });
};
