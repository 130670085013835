import { EventModel } from 'features/events/EventModel';
import {
  currentBroadcastFinishedSelector,
  currentEventBroadcastSelector
} from './selectors';
import { useAppSelector } from 'hooks/redux';
import { useNativeEvents } from 'hooks/useNativeEvents';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { useContext, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const useListenerAppPostBroadcastFinished = () => {
  const { postMessage } = useNativeEvents();
  const currentBroadcastFinished = useAppSelector(
    currentBroadcastFinishedSelector
  );
  const { isListenerAppWebview } = useContext(UserAgentContext);

  if (isListenerAppWebview) {
    console.log('[broadcastHooks] - useListenerAppPostBroadcastFinished');
    postMessage({
      currentBroadcastFinished: currentBroadcastFinished
    });
  }
};

export const useListenerAppPostBroadcastUrl = (event: EventModel | null) => {
  const { postMessage } = useNativeEvents();
  const { isListenerAppWebview } = useContext(UserAgentContext);
  const currentBroadcastUrl = useAppSelector(
    currentEventBroadcastSelector
  )?.url;
  const currentBroadcastFinished = useAppSelector(
    currentBroadcastFinishedSelector
  );
  const currentBroadcastUrlRef = useRef<string>('');

  if (
    isListenerAppWebview &&
    event &&
    currentBroadcastUrl &&
    currentBroadcastUrl !== currentBroadcastUrlRef.current &&
    !currentBroadcastFinished
  ) {
    const audioUrl =
      process.env.REACT_APP_ENV_NAME === 'development'
        ? 'https://radiomeuh2.ice.infomaniak.ch/radiomeuh2-128.mp3'
        : currentBroadcastUrl;
    console.log('[broadcastHooks] - useListenerAppPostBroadcastUrl');
    currentBroadcastUrlRef.current = audioUrl;
    postMessage({
      audioUrl: audioUrl,
      audioArtworkUrl: event.artwork.image.medium,
      audioId: event.id,
      audioOwnerId: event.broadcasterId,
      audioSessionId: uuidv4(),
      audioTitle: event.title
    });
  }
};

export const useCreatorAppPostMessageBroadcastStarted = (
  event: EventModel | null
) => {
  const { postMessage } = useNativeEvents();
  const { isCreatorAppWebview } = useContext(UserAgentContext);
  const nativeEventStarted = useAppSelector(
    (state) => state.events.nativeEventStarted
  );
  const currentBroadcastIsLive = useAppSelector(
    currentEventBroadcastSelector
  )?.isLive;
  const currentBroadcastFinished = useAppSelector(
    currentBroadcastFinishedSelector
  );

  if (
    isCreatorAppWebview &&
    nativeEventStarted &&
    event &&
    !event.fallback &&
    currentBroadcastIsLive &&
    !currentBroadcastFinished
  ) {
    console.log('[broadcastHooks] - useCreatorAppPostMessageBroadcastStarted');
    postMessage('broadcastStarted');
  }
};
