import React, { FunctionComponent, ReactNode, useContext } from 'react';
import { FullScreenTitle } from './FullScreenTitle';
import { useAppSelector } from 'hooks/redux';
import { UserAgentContext } from 'features/user-agent/userAgent';

interface FullScreenDetailsProps {
  title?: string | null;
  image?: ReactNode;
  meta?: ReactNode;
  liveStatus?: ReactNode;
  controls?: ReactNode;
}

export const FullScreenDetails: FunctionComponent<FullScreenDetailsProps> = (
  props
) => {
  const chatVisible = useAppSelector((state) => state.chat.isVisible);
  const { isDACreatorApp } = useContext(UserAgentContext);
  const imageWidth = chatVisible
    ? 'tw-max-w-[50%] lg:tw-max-w-[33%]'
    : 'tw-max-w-[33%] lg:tw-max-w-[420px]';
  const layoutDA = isDACreatorApp
    ? 'da-landscape:tw-max-w-[420px] da-landscape:tw-flex da-landscape:tw-gap-8 da-landscape:tw-items-center'
    : 'mobile-landscape:tw-max-w-[420px] mobile-landscape:tw-flex mobile-landscape:tw-gap-8 mobile-landscape:tw-items-center';
  const imageWidthDA = isDACreatorApp
    ? 'da-landscape:tw-w-[100px]'
    : 'mobile-landscape:tw-w-[100px]';

  return (
    <div
      className={`tw-w-full sm:tw-text-center tw-space-y-4 md:tw-space-y-8 [.ios_&]:tw-space-y-4 tw-text-white tw-px-8 ${layoutDA} [.embed_&]:tw-text-left [.embed_&]:tw-px-2`}
    >
      {props.image && (
        <div
          className={`${imageWidth} tw-mx-auto height-landscape:tw-w-[160px] ${imageWidthDA} [.ios_&]:mobile-portrait:tw-max-w-[90%] [.android_&]:mobile-portrait:tw-max-w-[90%] standalone:tw-max-w-[90%]`}
        >
          {props.image}
        </div>
      )}
      <div className="tw-flex-1">
        {props.title && <FullScreenTitle title={props.title} />}
        {props.liveStatus && (
          <div className="tw-my-2 md:tw-my-4 [.ios_&]:tw-my-2">
            {props.liveStatus}
          </div>
        )}
        {props.meta && (
          <div className="tw-flex tw-flex-wrap tw-space-x-8 tw-justify-center tw-items-center tw-mb-2 md:tw-mb-4 [.embed_&]:tw-justify-start [.embed_&]:tw-mb-0 [.embed_&]:tw-text-xl">
            {props.meta}
          </div>
        )}
        {props.controls && props.controls}
      </div>
    </div>
  );
};
