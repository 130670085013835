import React, { FunctionComponent, ReactNode, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { useCurrentEvent } from 'features/events/eventHooks';
import { useCurrentRecording } from 'features/recordings/recordingHooks';
import { useAudio } from 'features/audio/audio';
import { setMiniPlayerVisible } from 'features/channel/channelSlice';
import { setChatVisible } from 'features/chat/chatSlice';
import { useDispatch } from 'react-redux';
import { CardLinkNative } from './CardLinkNative';
import { useMediaQuery } from 'react-responsive';
import { unloadEvent, unloadRecording } from 'thunks/navigationThunks';
import { WebSocketContext } from 'features/hub/hub';
import { resetSearchState } from 'features/search/searchSlice';
import { setEventMinimised } from 'features/events/eventsSlice';

interface CardLinkProps {
  children: ReactNode;
  id: string;
  url: string | null;
  type: 'event' | 'recording';
  autoPlay: boolean | undefined;
  eventIsLive: boolean | undefined;
}

export const CardLink: FunctionComponent<CardLinkProps> = ({
  children,
  url,
  type,
  autoPlay,
  id,
  eventIsLive
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isNativeAppWebview } = useContext(UserAgentContext);
  const playing = useSelector((state: RootState) => state.audio.playing);
  const event = useCurrentEvent();
  const recording = useCurrentRecording();
  const { stop, unlockAudio } = useAudio();
  const isMobile = useMediaQuery({ query: '(max-width: 739px)' });
  const { leaveCrowd } = useContext(WebSocketContext);

  const onClickEvent = (e: React.MouseEvent<HTMLElement>) => {
    if (!eventIsLive && playing) {
      e.preventDefault();
      displayEventConfirmMessage();
    } else {
      dispatchEvents();
    }
  };

  const onClickRecording = (e: React.MouseEvent<HTMLElement>) => {
    if (event && playing) {
      e.preventDefault();
      displayEventConfirmMessage();
    } else {
      dispatchEvents();
    }
  };

  const displayEventConfirmMessage = () => {
    if (confirm('You are going to leave the current event, are you sure?')) {
      dispatchEvents();
      navigate(`${url}`);
    }
  };

  const dispatchEvents = () => {
    unlockAudio();
    dispatch(resetSearchState());
    dispatch(setMiniPlayerVisible(false));
    dispatch(setEventMinimised(false));
    dispatch(setChatVisible(isMobile ? false : true));

    if ((event && event.id !== id) || (recording && recording.id !== id)) {
      stop(true);
      leaveCrowd();
      dispatch(unloadEvent());
      dispatch(unloadRecording());
    }
  };

  if (!url) {
    return null;
  }

  if (isNativeAppWebview) {
    return (
      <CardLinkNative url={url} type={type}>
        {children}
      </CardLinkNative>
    );
  }

  return (
    <Link
      to={url}
      state={{
        prevPath: location.pathname,
        animate: true,
        autoPlay: autoPlay,
        hideLoader: true
      }}
      className="tw-text-black group-[.dark-contrast]/card:tw-text-white"
      onClick={type === 'event' ? onClickEvent : onClickRecording}
    >
      {children}
    </Link>
  );
};
