import { useSubdomain } from 'hooks/useSubdomain';
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks/redux';
import { fetchNetworkForChannel } from 'features/channel/channelSlice';
import { ChannelNetworkListLink } from './ChannelNetworkListLink';
import { ThumbImage } from 'components/Images/ThumbImage';
import { Loader } from 'components/Loader';
import { LogoImage } from 'components/LogoImage';

export const ChannelNetworkList: FunctionComponent = () => {
  const dispatch = useDispatch();
  const subdomain = useSubdomain();
  const network = useAppSelector((state) => state.channel.network);
  const networkIsLoaded = useAppSelector(
    (state) => state.channel.isNetworkLoaded
  );

  useEffect(
    function getNetwork() {
      if (subdomain) {
        dispatch(fetchNetworkForChannel(subdomain));
      }
    },
    [dispatch, subdomain]
  );

  if (!networkIsLoaded) {
    return <Loader fillComponent={true} />;
  }

  if (!network.length) {
    return <p>No other channels found in network.</p>;
  }

  return (
    <ul className="tw-m-0 tw-p-0 tw-list-none tw-space-y-4">
      {network.map((channel) => (
        <li
          key={channel.channelId}
          className="tw-flex tw-gap-2 tw-items-center"
        >
          <ChannelNetworkListLink channel={channel}>
            <div className="tw-flex tw-gap-2 tw-items-center tw-w-full">
              <LogoImage size={'small'} hasFallback={!channel.logoUrl}>
                <ThumbImage
                  artworkUrl={channel.logoUrl}
                  artworkMode={'logo'}
                  alt={`Logo for ${channel.username}`}
                />
              </LogoImage>
              {channel.username}
            </div>
          </ChannelNetworkListLink>
        </li>
      ))}
    </ul>
  );
};
