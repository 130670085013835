import React, { FunctionComponent } from 'react';
import { ChannelNetworkList } from './ChannelNetworkList';
import { usePageLoaded } from 'hooks/usePageLoaded';

export const ChannelNetwork: FunctionComponent = () => {
  usePageLoaded();

  return (
    <div className="container--wide tw-mt-8 [.ios_&]:tw-mt-0 [.android_&]:tw-mt-0">
      <ChannelNetworkList />
    </div>
  );
};
