import humanizeDuration from 'humanize-duration';
import { DateTime, DateTimeFormatOptions } from 'luxon';

const pad = function (num: number, size: number) {
  return ('000' + num).slice(size * -1);
};

const formatDateTime = (): DateTimeFormatOptions => {
  const currentLocal = DateTime.now().locale;
  if (currentLocal.includes('he')) {
    return DateTime.DATETIME_SHORT;
  }
  return DateTime.DATETIME_MED;
};

// Convert time to 00:00:00
const formatTime = (timeInSeconds: number): string => {
  const time: any = parseFloat(String(timeInSeconds)).toFixed(3),
    hours = Math.floor(time / 60 / 60),
    minutes = Math.floor(time / 60) % 60,
    seconds = Math.floor(time - minutes * 60);
  return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2);
};

// Convert amount to K/M/B
const formatNumber = (amount: number | null): string => {
  if (!amount || amount < 0) return '0';
  if (amount < 1e3) return '' + amount;
  if (amount >= 1e3 && amount < 1e6) return +(amount / 1e3).toFixed(1) + 'K';
  if (amount >= 1e6 && amount < 1e9) return +(amount / 1e6).toFixed(1) + 'M';
  if (amount >= 1e9 && amount < 1e12) return +(amount / 1e9).toFixed(1) + 'B';
  if (amount >= 1e12) return +(amount / 1e12).toFixed(1) + 'T';
  return '' + amount;
};

const toTitleCase = (phrase: string): string => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const removePunctuation = (string: string): string => {
  const punctuation = '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~';
  return string
    .split('')
    .filter(function (letter) {
      return punctuation.indexOf(letter) === -1;
    })
    .join('');
};

const pluralize = (count: number, noun: string, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'ms'
    }
  }
});

export {
  formatDateTime,
  formatNumber,
  formatTime,
  toTitleCase,
  removePunctuation,
  pad,
  pluralize,
  shortEnglishHumanizer
};
