import { Icon } from 'components/Icons';
import React, { FunctionComponent } from 'react';

interface LiveNowPillProps {
  isLive?: boolean | undefined;
  isFallback?: boolean | undefined;
}

export const LiveNowPill: FunctionComponent<LiveNowPillProps> = (props) => {
  if (props.isLive) {
    return (
      <div className="tw-bg-mixlr-red-20 uppercase tw-inline-flex tw-items-center tw-justify-center tw-text-base [.ios_&]:tw-text-xs tw-font-bold tw-rounded-full tw-h-[24px] tw-overflow-hidden">
        <div className="tw-bg-mixlr-red tw-text-white tw-flex tw-items-center tw-px-3 tw-gap-2 [.ios_&]:tw-px-2 tw-h-full tw-rounded-full">
          <Icon
            hidden={true}
            icon={'liveNow'}
            label={'Live'}
            height={12}
            width={12}
          />
          Live
        </div>
        <div className="tw-text-mixlr-red tw-flex tw-items-center tw-px-3 [.ios_&]:tw-px-2 tw-pl-2 tw-h-full">
          {props.isFallback ? 'Fallback' : 'Event'}
        </div>
      </div>
    );
  }

  return null;
};
