import React, {
  FunctionComponent,
  useContext,
  useState,
  useRef,
  memo
} from 'react';
import styles from '../EmojiPicker/styles.module.scss';
import { Icon } from 'components/Icons';
import { useOnClickOutside } from 'usehooks-ts';
import { ThemeContext } from 'features/theme/theme';
import { useAppSelector } from 'hooks/redux';
import GifPicker, { TenorImage, Theme, ContentFilter } from 'gif-picker-react';
import { useRect } from 'react-use-rect';

interface GiphyPickerProps {
  onGifClick: (gif: TenorImage) => void;
}

export const GiphyPicker: FunctionComponent<GiphyPickerProps> = (props) => {
  const giphyPickerRef = useRef<HTMLDivElement>(null);
  const [pickerOpen, togglePicker] = useState<boolean>(false);
  const { darkMode } = useContext(ThemeContext);
  const gifsInChatEnabled = useAppSelector(
    (state) => state.channel.channelDetails?.gifsInChatEnabled
  );
  const [pickerPosition, setPickerPosition] = useState<'right' | 'left'>(
    'right'
  );
  const [pickerButtonRef] = useRect((pickerButtonRefRect) => {
    setPickerPosition(pickerButtonRefRect.left < 100 ? 'left' : 'right');
  });

  const handleGifPickerClickOutside = () => {
    togglePicker(false);
  };

  useOnClickOutside(giphyPickerRef, handleGifPickerClickOutside);

  const onGifClick = (gif: TenorImage) => {
    props.onGifClick(gif);
    togglePicker(false);
  };

  if (!gifsInChatEnabled) {
    return null;
  }

  return (
    <div ref={giphyPickerRef}>
      <button
        ref={pickerButtonRef}
        onClick={() => togglePicker(!pickerOpen)}
        className={styles['emoji-picker__button']}
        type="button"
      >
        <Icon icon={'gif'} hidden={true} label={'Choose gif'} />
      </button>
      {pickerOpen && (
        <div
          className={`tw-absolute tw-bottom-full ${
            pickerPosition === 'left' ? 'tw-left-0' : 'tw-right-0'
          } tw-shadow-xl tw-rounded-xl tw-overflow-hidden tw-h-[280px] md:tw-h-[320px] mobile-landscape:tw-h-[220px] tw-z-50 tw-max-w-[280px] w-full`}
        >
          <GifPicker
            tenorApiKey={`${process.env.REACT_APP_TENOR_API_KEY}`}
            autoFocusSearch={false}
            onGifClick={onGifClick}
            theme={darkMode ? Theme.DARK : Theme.LIGHT}
            contentFilter={ContentFilter.MEDIUM}
            height={'100%'}
            width={'100%'}
          />
        </div>
      )}
    </div>
  );
};

export default memo(GiphyPicker);
