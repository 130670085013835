import { UserAgentContext } from 'features/user-agent/userAgent';
import { useAppSelector } from 'hooks/redux';
import { useContext } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

export const useSocialElementsDisabled = (): boolean => {
  const { isListenerAppWebview } = useContext(UserAgentContext);
  const channel = useAppSelector((state) => state.channel.channelDetails);

  return isListenerAppWebview && !!channel?.networkAvailable;
};

export const useBypassAccessCodeNetwork = (): boolean => {
  const { isListenerAppWebview } = useContext(UserAgentContext);
  const { pathname } = useLocation();
  const isNetworkPath = matchPath('/network', pathname);

  return isListenerAppWebview && !!isNetworkPath;
};
